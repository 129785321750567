import React from 'react';
import '../css/NotFound.css';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
                <header className="NotFound-header">
                    <h3 className="NotFound-h3">404 - Not Found</h3>
                    <p>The page you're looking for doesn't exist.</p>
                        <Link to="/" className="NotFound-link" >
                            Go back to Home
                        </Link>
               </header>
           );
};

export default NotFound;

