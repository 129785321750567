import '../css/Home.css';

function App() {
  return (
      <header className="App-header">
        <img src="https://public.xchip.in/logo/logo.png" className="App-logo" alt="logo" />
          <h3 className="App-h3">We Engineered the X Chip.</h3>
          <h5 className="App-h5">Tech for Everyone, Everywhere.</h5> 
          <div className="center">
            <div className="center-info">
              <p className="App-p">We started XChip to change how people interact with technology.</p>
              <h5 className="App-h5">Contact: <a href="mailto:mail@xchip.in" className="email-link">mail@xchip.in</a></h5>
            </div>
          </div>
      </header>
  );
}

export default App;
