import React from 'react';
import '../css/Header.css';

function Header() {
  return (
    <header className="header">
      <div className="header-content">
        <div className="header-text">
        </div>
      </div>
    </header>
  );
}

export default Header;
